<!--
 * @Description: 验证码
 * @Author: 琢磨先生
 * @Date: 2022-05-09 10:54:48
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-05 15:17:14
-->
<template>
  <el-card shadow="never" class="box">
    <el-form ref="query" :model="query" :inline="true">
      <el-form-item label="手机号">
        <el-input v-model="query.q" placeholder="请输入手机号码"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <!-- <div class="operator">
      <el-button type="primary" size="small" @click="onEdit" round icon="plus"
        >测试发送</el-button
      >
    </div> -->
  </el-card>
  <el-card class="box data">
    <el-table :data="tableData.data" border v-loading="loading">
      <!-- <el-table-column label="编号" prop="id" width="100"></el-table-column> -->
      <el-table-column
        label="应用"
        prop="app_name"
        width="140"
      ></el-table-column>
      <el-table-column
        label="类型"
        prop="type_text"
        width="140"
      ></el-table-column>
      <el-table-column
        label="手机号"
        prop="mobile_phone"
        width="140"
      ></el-table-column>
      <el-table-column label="验证码" prop="code" width="100"></el-table-column>
      <el-table-column label="过期时间" width="200">
        <template #default="scope">
          <el-link
            type="danger"
            :underline="false"
            v-if="new Date(scope.row.expire) < new Date()"
            >{{ scope.row.expire }}</el-link
          >
        </template>
      </el-table-column>
      <el-table-column
        label="匹配次数"
        prop="times"
        width="100"
      ></el-table-column>
      <el-table-column
        label="IP地址"
        prop="ipaddr"
        min-width="200"
      ></el-table-column>
      <el-table-column
        label="创建时间"
        prop="create_at"
        width="180"
      ></el-table-column>
    </el-table>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    >
    </el-pagination>
  </el-card>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      tableData: {
        counts: 0,
      },
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.$http.post("admin/v1/captcha", this.query).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
      });
      this.loading = false;
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
  },
};
</script>